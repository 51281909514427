module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bull Burger Grill","short_name":"Bull Burger Grill","start_url":"/","display":"standalone","background_color":"#f7f0eb","theme_color":"#a41833","icon":"src/images/bbg-mini-logo.inline.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6143f50112ddba9fdb635b0af2f32aff"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
